exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-animated-sticker-js": () => import("./../../../src/pages/animated-sticker.js" /* webpackChunkName: "component---src-pages-animated-sticker-js" */),
  "component---src-pages-app-privacy-js": () => import("./../../../src/pages/appPrivacy.js" /* webpackChunkName: "component---src-pages-app-privacy-js" */),
  "component---src-pages-app-terms-js": () => import("./../../../src/pages/appTerms.js" /* webpackChunkName: "component---src-pages-app-terms-js" */),
  "component---src-pages-ar-js": () => import("./../../../src/pages/ar.js" /* webpackChunkName: "component---src-pages-ar-js" */),
  "component---src-pages-avatar-js": () => import("./../../../src/pages/avatar.js" /* webpackChunkName: "component---src-pages-avatar-js" */),
  "component---src-pages-avatar-selfie-js": () => import("./../../../src/pages/avatar-selfie.js" /* webpackChunkName: "component---src-pages-avatar-selfie-js" */),
  "component---src-pages-case-studies-js": () => import("./../../../src/pages/case-studies.js" /* webpackChunkName: "component---src-pages-case-studies-js" */),
  "component---src-pages-celebrity-sticker-js": () => import("./../../../src/pages/celebrity-sticker.js" /* webpackChunkName: "component---src-pages-celebrity-sticker-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-delete-account-js": () => import("./../../../src/pages/delete-account.js" /* webpackChunkName: "component---src-pages-delete-account-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-usie-sticker-js": () => import("./../../../src/pages/usie-sticker.js" /* webpackChunkName: "component---src-pages-usie-sticker-js" */)
}

